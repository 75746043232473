import * as types from '../constants/eraserHeadProject'

export const eraserHeadProjectRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.ERASER_HEAD_PROJECT_REGISTER_REQUEST:
			return { loadingEraserHeadProjectRegister: true }
		case types.ERASER_HEAD_PROJECT_REGISTER_SUCCESS:
			return {
				successEraserHeadProjectRegister: true,
				loadingEraserHeadProjectRegister: false,
				eraserHeadProjectRegisterData: action.payload,
			}
		case types.ERASER_HEAD_PROJECT_REGISTER_FAIL:
			return {
				loadingEraserHeadProjectRegister: false,
				errorEraserHeadProjectRegister: action.payload,
			}
		case types.ERASER_HEAD_PROJECT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const eraserHeadProjectUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.ERASER_HEAD_PROJECT_UPDATE_REQUEST:
			return { loadingEraserHeadProjectUpdate: true }
		case types.ERASER_HEAD_PROJECT_UPDATE_SUCCESS:
			return {
				successEraserHeadProjectUpdate: true,
				loadingEraserHeadProjectUpdate: false,
				eraserHeadProjectUpdateData: action.payload,
			}
		case types.ERASER_HEAD_PROJECT_UPDATE_FAIL:
			return {
				loadingEraserHeadProjectUpdate: false,
				errorEraserHeadProjectUpdate: action.payload,
			}
		case types.ERASER_HEAD_PROJECT_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const getEraserHeadProjectByProjectIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_REQUEST:
			return { loadingGetEraserHeadProjectByProjectId: true }
		case types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_SUCCESS:
			return {
				successGetEraserHeadProjectByProjectId: true,
				loadingGetEraserHeadProjectByProjectId: false,
				eraserHeadProjectByProjectId: action.payload,
			}
		case types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_FAIL:
			return {
				loadingGetEraserHeadProjectByProjectId: false,
				errorGetEraserHeadProjectByProjectId: action.payload,
			}
		case types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_RESET:
			return {}
		default:
			return state
	}
}
