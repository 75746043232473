import axios from 'axios'
import * as types from '../constants/eraserHeadProject'

export const registerEraserHeadProject = (eraserData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.ERASER_HEAD_PROJECT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(`/api/erasers/projects/${eraserData.projectId}`, eraserData, config)

		dispatch({ type: types.ERASER_HEAD_PROJECT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.ERASER_HEAD_PROJECT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateEraserHeadProject = (eraserData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.ERASER_HEAD_PROJECT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/erasers/projects/update/${eraserData.projectId}`, eraserData, config)

		dispatch({ type: types.ERASER_HEAD_PROJECT_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.ERASER_HEAD_PROJECT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getEraserHeadProjectByProjectId = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/erasers/projects/${projectId}`, config)

		dispatch({ type: types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
