export const ERASER_HEAD_PROJECT_REGISTER_REQUEST = 'ERASER_HEAD_PROJECT_REGISTER_REQUEST'
export const ERASER_HEAD_PROJECT_REGISTER_SUCCESS = 'ERASER_HEAD_PROJECT_REGISTER_SUCCESS'
export const ERASER_HEAD_PROJECT_REGISTER_FAIL = 'ERASER_HEAD_PROJECT_REGISTER_FAIL'
export const ERASER_HEAD_PROJECT_REGISTER_RESET = 'ERASER_HEAD_PROJECT_REGISTER_RESET'

export const ERASER_HEAD_PROJECT_UPDATE_REQUEST = 'ERASER_HEAD_PROJECT_UPDATE_REQUEST'
export const ERASER_HEAD_PROJECT_UPDATE_SUCCESS = 'ERASER_HEAD_PROJECT_UPDATE_SUCCESS'
export const ERASER_HEAD_PROJECT_UPDATE_FAIL = 'ERASER_HEAD_PROJECT_UPDATE_FAIL'
export const ERASER_HEAD_PROJECT_UPDATE_RESET = 'ERASER_HEAD_PROJECT_UPDATE_RESET'

export const GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_REQUEST = 'GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_REQUEST'
export const GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_SUCCESS = 'GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_SUCCESS'
export const GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_FAIL = 'GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_FAIL'
export const GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_RESET = 'GET_ERASER_HEAD_PROJECT_BY_PROJECT_ID_RESET'
